import axios from 'axios'
import Message from 'ant-design-vue/es/message'
// axios.defaults.baseURL = 'http://192.168.6.111:6619/'
export const defaultRequest = {
  url: '',
  method: 'post',
  headers: {
    'Content-Type': 'application/json'
  },
  body: {},
  queries: {},
  params: {}
}
/**
 * Request a remote api to fetch data
 * @param {object} request request config,url/method/body/queries/params/headers...
 * @param {function} onError the error callback
 * @param {function} afterResponse a callback which will be called after response recieving
 */

const fetch = (request = {}, onError = err => err, afterResponse = res => res) => {
  request = Object.assign({}, defaultRequest, request)
  let { url, method, headers, body, queries, params } = request
  let promise = null
  method = method.toLowerCase()

  for (const key in params) {
    if (params[key] === undefined || params[key] === null) continue
    const regexp = new RegExp(`(/:${key}(?=[#?/]))|(/:${key}$)`, 'g')
    url = url.replace(regexp, `/${params[key]}`)
  }
  const core = () => {
    const config = {
      url,
      method,
      data: body,
      params: queries,
      headers
    }

    promise = axios(config)
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          Message.error(err.response.data.message)
        }
        onError instanceof Function && onError(err)
      })
      .then(response => {
        if (!response || response instanceof Error) return
        typeof afterResponse === 'function' && afterResponse(response.data, request)
        return response
      })
  }
  const api = {
    loading (before = () => {}, after = () => {}) {
      before()
      if (!promise) core()
      promise.finally(() => after())
      return api
    },
    done (cb = () => {}) {
      if (!promise) core()
      promise.then(response => {
        if (!response || response instanceof Error) return

        cb instanceof Function && cb(response.data)
        return response.data
      })
      return api
    }
  }

  return api
}

export default fetch
