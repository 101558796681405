<template>
  <div class="home">
    <div class="readImg">
      <div class="readTitle">经典阅读</div>
      <div class="readEnglish">Classic Reading</div>
    </div>
      <div class="headTop">
        <HeadTop />
      </div>
      <div class="content">
        <!-- <div v-if="list.length<=0"> <a-empty></a-empty> </div> -->
        <ul class="bookList">
          <li class="book" v-for="(item, index) in list" :key="index" @click="goBookDetail(item)">
            <div class="bookImg">
              <img class="coverImg" :src="$resBasePath+'/'+item.coverPath" alt="">
            </div>
            <div class="bookDetail">
              <div class="title">
                {{item.title}}
              </div>
              <div class="text">
                {{item.recommendReason}}
              </div>
              <div class="name">
                {{item.author}}
              </div>
            </div>
          </li>
        </ul>
        <div style="text-align:center;margin:15px 0px;">
          <a-config-provider>
            <a-pagination 
              show-quick-jumper
              :default-current="1"
              :page-size="pageSize"
              @change="handleListChange"
              show-less-items
              :total=total />
            </a-config-provider>
        </div>
    </div>
  </div>
</template>

<script>
import HeadTop from '@/components/HeadTop.vue'
import fetch from '@/utils/fetch.js'
import axios from 'axios'
export default {
  name: 'bookRoom',
  data(){
    return {
      fetch,
      pageSize: 5,
      pageNum: 1,
      total: 0,
      list: []
    }
  },
  components: {
    HeadTop
  },
  mounted () {
    this.getBookList()
  },
  methods:{
    handleListChange (current, pageSize) {
      this.pageNum = current
      this.pageSize = pageSize
    },
    goBookDetail(item){
      this.$router.push({name: 'bookRecommend'})
      sessionStorage.setItem('bookDetail',JSON.stringify(item))
    },
    getBookList(){
      let _this = this
      const param ={
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }
      axios({
        url: this.$baseUrl + 'official/bookStore/getBookPage',
        method: 'POST',
        data: param
      }).then(res => {
        if(res && res.data &&res.data.resultCode===0){ 
            _this.list = res.data.resultData.list
            _this.total = res.data.resultData.total
          }
      })
    }
  }
}
</script>

<style scoped lang="less">
  ul,li{
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .home{
    background: #f6f6f6;
    .readImg{
      width:100%;
      height:260px;
      background: url('../../assets/img/33.png');
      background-size: 100% 100%;
      position: relative;
      .readTitle{
        text-align: center;
        color: #323232;
        line-height: 55px;
        letter-spacing: 4px;
        font-size: 36px;
        padding-top: 80px;
      }
      .readEnglish{
        color: #3D3D3D;
        line-height: 27px;
        letter-spacing: 2px;
        font-size: 16px;
        text-align: center;
      }
    }
    .headTop{
      width: 1140px;
      margin: 0 auto;
    }
    .content{
      width: 1140px;
      background: #fff;
      margin: auto;
      padding: 20px 30px 30px;
      margin-bottom: 30px;
      .bookList{
        min-height: 600px;
        .book{
          cursor: pointer;
          display: flex;
          border-bottom: 1px solid #eee9de;
          height: 170px;
          .bookImg{
            margin-top: 20px;
            .coverImg{
              width: 120px;
              height: 120px;
            }
          }
          .bookDetail{
            margin-top: 20px;
            margin-left: 10px;
            .title{
              height: 30px;
              font-size: 18px;
              font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
              font-weight: 500;
              color: #000000;
              line-height: 30px;

            }
            .text{
              margin-top: 8px;
              height: 52px;
              font-size: 16px;
              font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
              font-weight: 400;
              color: #000000;
              line-height: 26px;
              overflow: hidden;
              text-overflow: ellipsis;
              display:-webkit-box;
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2;
            }
            .name{
              margin-top: 10px;
              width: 94px;
              text-align: center;
              height: 24px;
              line-height: 24px;
              background: #F6F4EE;
              border-radius: 4px;
              color: #C59A61;
              font-size: 14px;
              font-weight: 500
            }
          }
        }
      }

    }
  }
  .ant-pagination-item-active a{
    color: #db5647;
  }
</style>